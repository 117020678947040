import {
  useCallback, useEffect, useRef, useState
} from 'react';

import type {
  AirgapAPI, PreInitTranscendAPI, TrackingConsent, TranscendAPI
} from '@transcend-io/airgap.js-types';

type TranscendReadyCallback = (api: TranscendAPI) => void;
type AirgapReadyCallback = (api: AirgapAPI) => void;

/** A stub for `window.airgap` to be used before airgap is actually loaded */
type PreInitAirgapAPI = Required<Pick<AirgapAPI, 'ready' | 'readyQueue'>>;

/**
 * Add `window.airgap` and `window.transcend` to the global namespace
 */
declare global {
  interface Window {
    airgap?: AirgapAPI | PreInitAirgapAPI;
    transcend?: PreInitTranscendAPI | TranscendAPI;
  }
}

interface ConsentStatus { purposes: TrackingConsent; }

export interface ConsentAPI {
  airgap?: AirgapAPI;
  hasAdvertisingConsent?: boolean;
  hasAnalyticsConsent?: boolean;
  hasFunctionalConsent?: boolean;
  subscribeToConsentChanges?: (callback: (consent: ConsentStatus) => void) => () => void;
  transcend?: TranscendAPI;
}

export const useConsentManager = (): ConsentAPI => {
  const [airgap, setAirgap] = useState<AirgapAPI | undefined>();
  const [transcend, setTranscend] = useState<TranscendAPI | undefined>();
  const [consentStatus, setConsentStatus] = useState<ConsentStatus | null>(null);
  const subscribers = useRef<Set<(consent: ConsentStatus) => void>>(new Set());

  useEffect(() => {
    if (!window.transcend?.ready) {
      const preInitTranscend: PreInitTranscendAPI = {
        ready(callback: TranscendReadyCallback) {
          this.readyQueue.push(callback);
        },
        readyQueue: [],
        ...window.transcend,
      };
      window.transcend = preInitTranscend;
    }

    if (!window.airgap?.ready) {
      const preInitAirgap: PreInitAirgapAPI = {
        ready(callback: AirgapReadyCallback) {
          this.readyQueue.push(callback);
        },
        readyQueue: [],
        ...window.airgap,
      };
      window.airgap = preInitAirgap;
    }

    if (!transcend) {
      window.transcend.ready((transcend) => {
        setTranscend(transcend);
      });
    }

    if (!airgap) {
      window.airgap.ready((airgap) => {
        setAirgap(airgap);
      });
    }

    const handleConsentChange = (event: Event) => {
      const { consent } = (event as CustomEvent).detail as { consent: ConsentStatus };
      setConsentStatus(consent);
      for (const callback of subscribers.current) {
        callback(consent);
      }
    };

    const handleConsentResolution = (event: Event) => {
      const { consent } = (event as CustomEvent).detail as { consent: ConsentStatus };
      setConsentStatus(consent);
      for (const callback of subscribers.current) {
        callback(consent);
      }
    };

    if (airgap) {
      airgap.addEventListener('consent-change', handleConsentChange);
      airgap.addEventListener('consent-resolution', handleConsentResolution);
    }

    return () => {
      if (airgap) {
        airgap.removeEventListener('consent-change', handleConsentChange);
        airgap.removeEventListener('consent-resolution', handleConsentResolution);
      }
    };
  }, [airgap, transcend]);

  const hasFunctionalConsent = !!consentStatus?.purposes?.Functional;
  const hasAdvertisingConsent = !!consentStatus?.purposes?.Advertising;
  const hasAnalyticsConsent = !!consentStatus?.purposes?.Analytics;

  const subscribeToConsentChanges = useCallback((callback: (consent: ConsentStatus) => void) => {
    subscribers.current.add(callback);

    return () => {
      subscribers.current.delete(callback);
    };
  }, []);

  return {
    airgap,
    hasAdvertisingConsent,
    hasAnalyticsConsent,
    hasFunctionalConsent,
    subscribeToConsentChanges,
    transcend,
  };
};
